.settings {
    padding: 2rem;
    min-height: 100vh;
    transition: all 0.3s ease;
  }
  
  .settings-section {
    margin: 2rem 0;
    padding: 1rem;
    border-radius: 8px;
    max-width: 600px;
  }
  
  .settings select {
    padding: 0.5rem;
    border-radius: 4px;
    margin-top: 1rem;
    width: 200px;
  }
  .items-list {
    margin-top: 1rem;
  }
  
  .list-item {
    transition: all 0.2s ease;
  }
  
  .list-item:hover {
    transform: translateX(4px);
  }
  
  .list-item button:hover {
    opacity: 0.9;
  }