@import './base/_variables.css';
@import './base/_typography.css';
@import './base/_animations.css';
@import './base/_reset.css';

@import './components/_forms.css';
@import './components/_tables.css';
@import './components/_buttons.css';
@import './components/_navigation.css';
@import './components/_loading.css';
@import './components/_notification.css';

@import './features/expenses/_expense-form.css';
@import './features/expenses/_expense-list.css';
@import './features/expenses/_expense-table.css';
@import './features/auth/_auth-form.css';
@import './features/settings/_settings.css';

@media (max-width: 768px) {
    .app-container {
      padding: 10px;
    }
  
    .expenses-table {
      display: block;
      overflow-x: auto;
    }
  
    .navigation ul {
      flex-direction: column;
      gap: 10px;
    }
  
    .form-group {
      flex-direction: column;
    }
  
    .dialog {
      width: 90%;
      margin: 20px;
    }
  }