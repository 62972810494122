.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .dialog {
    background: #ffffff;
    color: #000000;
    padding: 35px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    border: 1px solid #ddd;
  }
  
  .dialog p {
    font-size: 18px;
    margin-bottom: 25px;
    text-align: center;
    font-weight: 500;
    line-height: 1.5;
    background: #ffffff;
    padding: 15px;
    border-radius: 4px;
    color: #000000;
  }
  
  .dialog-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  
  .cancel-btn {
    padding: 12px 24px;
    border: none;
    background: #e0e0e0;
    color: #333333;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.2s;
  }
  
  .confirm-btn {
    padding: 12px 24px;
    background: #dc3545;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.2s;
  }
  
  .cancel-btn:hover {
    background: #d0d0d0;
  }
  
  .confirm-btn:hover {
    background: #c82333;
  }