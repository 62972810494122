.add-expense-form {
    padding: var(--spacing-lg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin-bottom: var(--spacing-lg);
  }
  
  .add-expense-form h3 {
    margin-bottom: 1.5rem;
  }
  
  .expense-form {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  
  .error-message {
    margin-top: var(--spacing-md);
    color: var(--color-error);
  }

  .participants-selector {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 8px;
    margin-top: var(--spacing-sm);
  }
  
  .participant-checkbox {
    display: flex;
    align-items: center;
    padding: var(--spacing-sm);
    background: var(--color-surface);
    border-radius: var(--border-radius);
    cursor: pointer;
  }
  
  .participant-checkbox input {
    margin: 0;
  }

  .participants-control {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .all-participants-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .participants-control select {
    min-height: 100px;
  }

  .expense-form-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 24px;
    row-gap: var(--spacing-xl);
  }
  
  .form-group {
    grid-column: span 4;
  }
  
  .form-group.amount-field {
    grid-column: span 2;
  }
  
  .form-group.date-field {
    grid-column: span 2;
  }
  
  .form-label {
    font-weight: 500;
    color: var(--color-text);
  }
  
  .form-input {
    padding: var(--spacing-md);
    border-radius: var(--radius-md);
    border: 1px solid var(--color-border);
    font-size: 1rem;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .form-input:focus,
  select:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px rgba(76, 154, 255, 0.1);
  }
  
  .submit-button {
    padding: var(--spacing-md);
    border: none;
    border-radius: var(--radius-md);
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s ease, transform 0.2s ease;
  }
  
  .submit-button:hover:not(:disabled) {
    transform: translateY(-1px);
  }
  
  .submit-button:active:not(:disabled) {
    transform: translateY(0);
  }
  
  .error-message {
    margin-top: var(--spacing-md);
    padding: var(--spacing-md);
    border-radius: var(--radius-md);
    background-color: rgba(239, 68, 68, 0.1);
  }

  /* Make form full-width on mobile */
  @media (max-width: 768px) {
    .expense-form-grid {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  
    .form-group,
    .form-group.amount-field,
    .form-group.date-field {
      grid-column: 1 / -1;
    }
  }