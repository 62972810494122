.auth-form-container {
    max-width: 400px;
    margin: 100px auto;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 8px;
  }
  
  .auth-form-container h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .auth-form-container form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .auth-form-container input {
    padding: 0.5rem;
    font-size: 1rem;
  }
  
  .auth-form-container button {
    padding: 0.5rem;
    font-size: 1rem;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
  }
  
  .auth-form-container button[disabled] {
    background-color: #ccc;
  }
  
  .auth-form-container .switch-button {
    width: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: var(--border-radius);
    cursor: pointer;
  }
  
  .auth-form-container p {
    text-align: center;
    margin-top: 1rem;
    color: var(--color-error);
  }


  .landing-page {
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: var(--spacing-xl);
    max-width: 1400px;
    margin: 0 auto;
    padding: var(--spacing-xl) var(--spacing-lg);
    min-height: 100vh;
    align-items: center;
  }
  
  .welcome-section {
    padding: var(--spacing-xl);
    animation: fadeIn 0.8s ease-out;
  }
  
  .welcome-section h1 {
    font-size: 3.5rem;
    margin-bottom: var(--spacing-md);
    color: var(--color-primary);
    font-weight: 700;
    line-height: 1.2;
  }
  
  .app-description {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: var(--spacing-xl);
    color: var(--color-text-secondary);
    max-width: 800px;
  }
  
  .features-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-lg);
    margin-top: var(--spacing-xl);
  }
  
  .feature-item {
    padding: var(--spacing-lg);
    background: var(--color-surface);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 1px solid var(--color-border);
  }
  
  .feature-item:hover {
    transform: translateY(-4px);
    box-shadow: var(--box-shadow-lg);
  }
  
  .feature-item h3 {
    font-size: 1.4rem;
    margin-bottom: var(--spacing-sm);
    color: var(--color-primary);
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }
  
  .feature-item p {
    color: var(--color-text-secondary);
    line-height: 1.5;
  }
  
  .auth-form-container {
    background: var(--color-surface);
    padding: var(--spacing-xl);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow-lg);
    position: sticky;
    top: var(--spacing-xl);
    height: fit-content;
    border: 1px solid var(--color-border);
    animation: slideIn 0.6s ease-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes slideIn {
    from { opacity: 0; transform: translateX(40px); }
    to { opacity: 1; transform: translateX(0); }
  }
  
  @media (max-width: 1024px) {
    .landing-page {
      grid-template-columns: 1fr;
      padding: var(--spacing-lg);
    }
    
    .welcome-section {
      padding: var(--spacing-lg) 0;
    }
    
    .welcome-section h1 {
      font-size: 2.5rem;
    }
    
    .auth-form-container {
      position: static;
      margin-top: var(--spacing-xl);
    }
    
    .features-list {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .landing-page {
      grid-template-columns: 1fr;
    }
    
    .auth-form-container {
      position: static;
    }
  }

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 2px solid var(--color-border);
  border-radius: 8px;
  background: var(--color-background);
  color: var(--color-text);
  transition: all 0.3s ease;
}

.form-group input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 3px var(--color-primary-light);
}

.floating-label {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-text-secondary);
  transition: all 0.3s ease;
  pointer-events: none;
}

.form-group input:focus + .floating-label,
.form-group input.has-content + .floating-label {
  top: 0;
  font-size: 0.8rem;
  background: var(--color-background);
  padding: 0 4px;
}

.password-group {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--color-text-secondary);
  cursor: pointer;
  padding: 0;
}

.password-requirements {
  margin: 8px 0;
  font-size: 0.9rem;
}

.password-requirements p {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
  color: var(--color-text-secondary);
}

.password-requirements .valid {
  color: var(--color-success);
}

.password-requirements .invalid {
  color: var(--color-error);
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.loading-spinner {
  display: flex;
  align-items: center;
  gap: 8px;
}

.spinner-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.message {
  margin-top: 1rem;
  padding: 12px;
  border-radius: 8px;
  font-size: 0.9rem;
  text-align: center;
}

.message.success {
  background-color: var(--color-success-light);
  color: var(--color-success);
  border: 1px solid var(--color-success);
}

.message.error {
  background-color: var(--color-error-light);
  color: var(--color-error);
  border: 1px solid var(--color-error);
}