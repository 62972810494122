/* Common card styles */
.card-container {
  background: var(--color-surface);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--color-border);
  margin-bottom: var(--spacing-lg);
}

/* Common table styles */
.table-base {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: var(--border-radius);
  overflow: hidden;
}

/* Common header styles */
.section-header {
  padding: var(--spacing-lg);
  border-bottom: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
}

.component-header {
  border-bottom: 1px solid var(--color-border);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.component-title {
  color: var(--color-text-highlight);
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

.component-container {
  background-color: var(--color-surface);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--color-border);
  margin-bottom: 32px;
}

@media (prefers-color-scheme: dark) {
  .card-container {
    background: var(--color-surface-dark);
  }
  
  .table-base td {
    background: var(--color-surface-dark);
  }
}

[data-theme='dark'] .component-container {
  background-color: #242424;
}
