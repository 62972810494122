html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

* {
  transition: background-color 0.3s ease, color 0.3s ease;
}

:root {
  /* Base theme variables */
  --text-highlight: #FFFFFF;
  --text-secondary: #A0A0A0;
  --border-color: #404040;
  
  /* Common spacing */
  --spacing-base: 8px;
  --spacing-lg: 16px;
  --spacing-xl: 24px;
  
  /* Common radii */
  --radius-base: 6px;
  --radius-lg: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
