.btn {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius);
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-primary {
  background: var(--color-primary);
  color: white;
}

.btn-error {
  background: var(--color-error);
  color: white;
}

.btn-outline {
  background: transparent;
  border: 2px dashed var(--color-border);
  color: var(--color-primary);
}

