.expense-lists-container {
  padding: var(--spacing-xl);
  max-width: 1400px;
  margin: 0 auto;
  min-height: 100vh;
  background: var(--background-color);
}

.lists-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-xl);
  padding-bottom: var(--spacing-lg);
  border-bottom: 1px solid var(--color-border);
}

.header-text {
  color: var(--color-text);
}

.header-text h1 {
  font-size: 2.5rem;
  margin-bottom: var(--spacing-xs);
  font-weight: 700;
  color: var(--color-text-highlight);
  background: none;
}

.header-text h2 {
  font-size: 1.2rem;
  color: var(--color-text-secondary);
  opacity: 0.9;
}

.create-list-btn {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-lg);
  background: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.create-list-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.create-list-btn svg {
  font-size: 1.2rem;
}

.lists-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: var(--spacing-lg);
}

.list-card {
  background: var(--color-surface);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-border);
  box-shadow: var(--box-shadow);
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.list-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  opacity: 0;
  transition: opacity 0.2s ease;
}

.list-card:hover {
  transform: translateY(-4px);
  box-shadow: var(--box-shadow-lg);
  background: var(--color-surface-hover);
}

.list-card:hover::before {
  opacity: 1;
}

.list-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.list-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--color-text);
}

.list-card-actions {
  display: flex;
  gap: var(--spacing-xs);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.list-card:hover .list-card-actions {
  opacity: 1;
}

.list-card-btn {
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius);
  border: none;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 4px;
}

.edit-btn {
  background: var(--color-primary);
}

.delete-btn {
  background: var(--color-error);
}

.share-btn {
  background: green;
}

.list-card-info {
  color: var(--color-text-secondary);
  font-size: 0.9rem;
}

.list-card-info p {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-xs);
}

.list-card-info p:last-child {
  margin-bottom: 0;
  border-top: 1px solid var(--color-border);
  padding-top: var(--spacing-xs);
  margin-top: var(--spacing-xs);
}

.participants-tag {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 12px;
  margin: 2px;
  font-size: 0.85rem;
  transition: transform 0.2s ease;
}

.participants-tag:hover {
  transform: translateY(-1px);
}

.participants-tag.registered {
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
}

.delete-modal {
  background: var(--color-surface);
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  max-width: 400px;
  width: 90%;
}

.delete-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.share-modal {
  background: var(--color-surface);
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  max-width: 400px;
  width: 90%;
}

.share-input {
  width: 100%;
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  background: var(--color-surface);
  color: var(--color-text);
}

.share-actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.share-requests-container {
  margin-bottom: var(--spacing-xl);
  background: var(--color-surface);
  border-radius: var(--border-radius);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-border);
}

.share-request {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
}

.share-request:last-child {
  border-bottom: none;
}

.share-request-actions {
  display: flex;
  gap: var(--spacing-sm);
}

.accept-btn {
  background: var(--color-success);
  color: white;
  border: none;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: var(--border-radius);
  cursor: pointer;
}

.reject-btn {
  background: var(--color-error);
  color: white;
  border: none;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: var(--border-radius);
  cursor: pointer;
}

.share-request-message {
  margin-top: var(--spacing-xs);
  font-style: italic;
  color: var(--color-text-secondary);
  font-size: 0.9rem;
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--color-surface-hover);
  border-radius: var(--border-radius);
  max-width: 500px;
}

.section {
  margin-bottom: var(--spacing-lg);
}

.section h4 {
  color: var(--color-text);
  margin-bottom: var(--spacing-sm);
}

.input-group {
  display: flex;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--color-surface-hover);
  border-radius: var(--border-radius);
  font-size: 0.9rem;
}

.tag.registered-user {
  background: var(--color-primary);
  color: white;
}

@media (max-width: 768px) {
  .expense-lists-container {
    padding: var(--spacing-md);
  }
  
  .lists-header {
    flex-direction: column;
    gap: var(--spacing-md);
    text-align: center;
  }
  
  .header-text h1 {
    font-size: 2rem;
  }
  
  .create-list-btn {
    width: 100%;
    justify-content: center;
  }

  .list-card-actions {
    opacity: 1;
  }
}
