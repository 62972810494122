@import '../../common/_shared-styles.css';

.expenses-table {
  margin-top: var(--spacing-md);
}

.table-header {
  background-color: var(--color-surface-dark);
  color: var(--color-text-highlight);
  padding: 12px 16px;
  font-weight: 600;
  border-bottom: 2px solid var(--color-border);
  text-align: left;
}

.text-right {
  text-align: right;
}

.expense-row {
  background-color: var(--color-surface);
  border-bottom: 1px solid var(--color-border);
  transition: all 0.2s ease;
}

.expense-row:hover {
  background-color: var(--color-surface-hover);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.expense-cell {
  padding: 12px 16px;
  color: var(--color-text);
}

.expense-cell.secondary {
  color: var(--color-text-secondary);
}

.expense-cell.amount {
  font-weight: 600;
  text-align: right;
}

.expense-cell.actions {
  text-align: right;
  opacity: 0;
}

.expense-row:hover .expense-cell.actions {
  opacity: 1;
}

.view-all-link {
  color: var(--color-primary);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
}

.amount-cell {
  font-weight: 600;
  text-align: right;
}

.delete-button {
  background: var(--color-error);
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
}

.delete-button:hover {
  background-color: #c82333;
}

.sortable:hover {
  background-color: var(--surface-hover);
}

.expenses-table tr:hover {
  background-color: var(--surface-hover);
}
  
.date-cell {
  white-space: nowrap;
  color: var(--color-text-secondary);
}
  
.sortable:hover {
  background-color: #e9ecef;
}

.participants-list {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
  
.expenses-table td {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: var(--spacing-md);
  color: var(--color-text);
  border-bottom: 1px solid var(--color-border);
  background: var(--color-surface);
}

@media (prefers-color-scheme: dark) {
  .expenses-table td {
    background-color: var(--color-surface-dark);
  }

  .expenses-table tr:hover td {
    background-color: var(--color-surface-dark-hover);
  }
}

.positive-amount {
  color: var(--color-success) !important;
}

.negative-amount {
  color: var(--color-error) !important;
}

.description-cell {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.action-cell {
  width: 100px;
  text-align: right;
}

.action-button {
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius);
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.edit-button {
  background: var(--color-primary);
  color: white;
  margin-right: var(--spacing-xs);
}

.action-button:hover {
  opacity: 0.9;
}

.expenses-table tbody tr:hover {
  background: var(--color-surface-hover);
}

.empty-state {
  text-align: center;
  padding: var(--spacing-xl);
  color: var(--color-text-secondary);
  background: var(--color-surface-hover);
  border-radius: var(--border-radius);
  margin: var(--spacing-xl) 0;
}

@media (max-width: 768px) {
  .description-cell {
    max-width: 150px;
  }

  .expenses-table th:not(:first-child):not(:last-child),
  .expenses-table td:not(:first-child):not(:last-child) {
    display: none;
  }
}